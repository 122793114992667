<template>
  <div>
    <div class="show-menu-container d-md-none" @click="clickButton" >
      <button data-toggle="collapse"
      data-target="#link-list-collapse"
      aria-expanded="false"
      aria-controls="link-list-collapse"
      ref="btnContainer"
      class="btn btn-none btn-show-menu">
        <span>{{ currentPageTitle }}</span>
        <span class="arrow-down-end"></span>
      </button>
    </div>
    <div class="link-list collapse" id="link-list-collapse">
      <cms-content id="product_care_left_nav" class="text-left"/>
    </div>
  </div>
</template>

<script>
import CmsContent from '@/components/CmsContent'
import $ from 'jquery'

export default {
  data () {
    return {
      currentPageTitle: ''
    }
  },
  mounted () {
    var $this = this
    // manage external link and push to router
    $(document).on('click', 'div[data-id="product_care_left_nav"].cms-content a', function (e) {
      e.preventDefault()
      var href = $(this).attr('href')
      $this.$router.push({ path: $this.$router.resolve(href).href })
    })
    this.currentPageTitle = this.$store.state.cms.meta.title
  },
  methods: {
    clickButton () {
      $('.btn-show-menu').click()
    }
  },
  components: {
    CmsContent
  }
}
</script>

<style>
  div[data-id="product_care_left_nav"] a{
    display: block;
    color: #191919;
    text-transform: uppercase;
    letter-spacing: 0.7px;
    font-size: 0.8125rem;
    margin-bottom: 15px;
    opacity: 1;
  }
  .link-list{
    padding: 10px 0 15px 15px;
    font-size: 0.8125rem;
    font-weight: 600;
    letter-spacing: 0.65px;
    line-height: 15px;
  }
</style>
